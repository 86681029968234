const ContasReceberPagarParcelasPorVencimentoPortadorPlanilha = [
  {
    titulo: 'Data Vencimento',
    valor: 'valores.dataVencimento',
  },
  {
    titulo: 'Núm Documento',
    valor: 'listaConta.numeroDocumento',
  },
  {
    titulo: 'Sequência',
    valor: 'listaConta.sequencia',
  },
  {
    titulo: 'Quantidade Parcelas',
    valor: 'listaConta.quantidadeParcelas',
  },
  {
    titulo: 'Data Emissao',
    valor: 'listaConta.dataEmissao',
  },

  {
    titulo: 'Código',
    valor: 'listaConta.codigoCliente',
  },

  {
    titulo: 'Cliente/Fornecedor',
    valor: 'listaConta.nomeCliente',
  },

  {
    titulo: 'Valor Parcela',
    valor: 'listaConta.valorParcela',
  },

  {
    titulo: 'Total Parcela',
    valor: 'listaConta.valorTotalParcela',
  },
  {
    titulo: 'Total Liquidado',
    valor: 'listaConta.valorTotalLiquidado',
  },
  {
    titulo: 'Total Aberto',
    valor: 'listaConta.valorTotalAberto',
  },
  {
    titulo: 'Situação',
    valor: 'listaConta.situacao',
  },
];

export default ContasReceberPagarParcelasPorVencimentoPortadorPlanilha;
