/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import * as Yup from 'yup';
import { IFormCiaHandles, UseFieldCia } from '@elogestor/unformcia';
import {
  IImportacaoNfeTerceiroEtapa1ItemLista,
  IImportacaoNfeTerceiroEtapa1ItemDesmembradoLista,
  ValidarCodigoBarrasGtin,
  ImportacaoXmlTerceiroTipoDataEnum,
} from '@elogestor/util';
import { IoMdAdd } from 'react-icons/io/index.mjs';
import Input from '../../../../../../../Componentes/Inputs/Input';
import InputDate from '../../../../../../../Componentes/Inputs/InputDate';
import InputLabel from '../../../../../../../Componentes/Inputs/InputLabel';
import Divisor from '../../../../../../../Componentes/Divisor';
import InputDecimal from '../../../../../../../Componentes/Inputs/InputDecimal';
import { Tabela } from '../../../../../../../Componentes/Tabela/styles';
import GetValidationErrors from '../../../../../../../Util/Erro/GetValidationErrors';
import InputDateTime from '../../../../../../../Componentes/Inputs/InputDateTime';
import { CheckboxContainer } from '../styles';
import InputCheckbox from '../../../../../../../Componentes/Inputs/InputCheckbox';
import InputInteiro from '../../../../../../../Componentes/Inputs/InputInteiro';
import InputAutoCompleteUnidadeMedida from '../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteUnidadeMedida';
import InputAutoCompleteProduto from '../../../../../../../Componentes/Inputs/AutoComplete/Suprimentos/InputAutoCompleteProduto';
import { Sleep } from '../../../../../../../Componentes/Padrao/MenuPrincipal/Scripts';
import TratarAvisos from '../../../../../../../Util/Aviso/TratarAvisos';
import TratarErros from '../../../../../../../Util/Erro/TratarErros';
import InputHidden from '../../../../../../../Componentes/Inputs/InputHidden';
import BtnPadraoButton from '../../../../../../../Componentes/Buttons/BtnPadraoButton';
import VinculoPedidoCompraLista from './VinculoPedidoCompra';
import VinculoPedidoCompraHook from './VinculoPedidoCompra/Hook';
import InputAutoCompleteTagPedidoCompraItem from '../../../../../../../Componentes/Inputs/AutoCompleteTag/Suprimentos/InputAutoCompleteTagPedidoCompraItem';
import { UseParametros } from '../../../../../../../Hooks/ParametrosHook';

export interface IImportacaoNfeTerceiroEtapa1Ref {
  handleSetarListaItem(listaValor: any[]): void;
  handleObterListaItem(): IImportacaoNfeTerceiroEtapa1ItemLista[];
  handleValidar(): Promise<boolean>;
}

interface IImportacaoNfeTerceiroEtapa1Props {
  name: string;
  formRef: React.RefObject<IFormCiaHandles>;
  loading: boolean;
  setLoading(valor: boolean): void;
  refresh(): void;
}

const ImportacaoNfeTerceiroEtapa1: React.ForwardRefRenderFunction<
  IImportacaoNfeTerceiroEtapa1Ref,
  IImportacaoNfeTerceiroEtapa1Props
> = ({ name, formRef, setLoading, refresh, loading }, ref) => {
  const [listaItem, setListaItem] = useState<
    IImportacaoNfeTerceiroEtapa1ItemLista[]
  >([]);

  const parametros = UseParametros();

  const { fieldName, registerField } = UseFieldCia(name);

  const valorRef = useRef<IImportacaoNfeTerceiroEtapa1ItemLista[]>([]);

  const [listaValor, setListaValor] = useState<
    IImportacaoNfeTerceiroEtapa1ItemLista[]
  >([]);

  const [abrirVinculoPedidoCompra, setAbrirVinculoPedidoCompra] =
    useState(false);

  const [indexItemSelecionado, setIndexItemSelecionado] = useState<
    null | number
  >(null);

  const [indexDesmembradoSelecionado, setIndexItemDesmembradoSelecionado] =
    useState<null | number>(null);

  const [
    listaPedidosComprasVinculadosFront,
    setListaPedidosComprasVinculadosFront,
  ] = useState<any[]>([]);

  useEffect(() => {
    valorRef.current = listaValor;
  }, [listaValor]);

  useEffect(() => {
    registerField<IImportacaoNfeTerceiroEtapa1ItemLista[] | undefined>({
      name: fieldName,
      ref: valorRef,

      getValue() {
        return valorRef.current;
      },

      getDataRecuperarFormulario() {
        return valorRef.current;
      },

      setValue(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      setSemExecutarEvento(_, value) {
        if (!value) setListaValor([]);
        else setListaValor(value);
      },

      clearValue(_, valorInicial) {
        this.setValue(_, valorInicial || []);
      },

      validarSeAlterou() {
        return false;
      },

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      setDisabled() {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleObterDados = useCallback(async () => {
    const listaEtapa1 = formRef.current?.getFieldValue('listaEtapa1');
    const dataLancamento = formRef.current?.getFieldValue('dataLancamento');
    const dataEntradaEstabelecimento = formRef.current?.getFieldValue(
      'dataEntradaEstabelecimento'
    );
    const copiarCodigoDescricaoNovosProdutos = formRef.current?.getFieldValue(
      'copiarCodigoDescricaoNovosProdutos'
    );
    const chaveAcesso = formRef.current?.getFieldValue('chaveAcesso');
    const permiteAlterarPessoaCliente = formRef.current?.getFieldValue(
      'permiteAlterarPessoaCliente'
    );
    const pessoaCliente = formRef.current?.getFieldValue('pessoaCliente');
    const valorTotalNota = formRef.current?.getFieldValue('valorTotalNota');

    const data = {
      listaEtapa1,
      dataLancamento,
      dataEntradaEstabelecimento,
      copiarCodigoDescricaoNovosProdutos,
      chaveAcesso,
      permiteAlterarPessoaCliente,
      pessoaCliente,
      valorTotalNota,
    };

    return data;
  }, [formRef]);

  const handleValidar = useCallback(async (): Promise<boolean> => {
    try {
      const listaMensagemAviso: string[] = [];
      const listaMensagemErro: string[] = [];
      let etapaValida = true;

      const data: any = await handleObterDados();
      formRef.current?.setErrors({});
      data.listaEtapa1.forEach(
        (item: IImportacaoNfeTerceiroEtapa1ItemLista) => {
          const totalPercentual = item.listaItemDesmembrado.reduce(
            (
              acumulador: number,
              itemDesmembrado: IImportacaoNfeTerceiroEtapa1ItemDesmembradoLista
            ) => {
              acumulador += Number(itemDesmembrado.percentual);
              return acumulador;
            },
            0
          );

          if (totalPercentual.Arredondar(10) !== 100) {
            listaMensagemErro.push(
              `A Soma dos Percentuais do Item: ${item.itemNf.sequencia} tem que ser 100%!`
            );
          }

          let listaItemDesmembradoRepetido = '';

          item.listaItemDesmembrado.forEach(
            (
              itemDesmembrado: IImportacaoNfeTerceiroEtapa1ItemDesmembradoLista
            ) => {
              const itemRepetido =
                item.listaItemDesmembrado.filter(
                  (e) =>
                    e.sequencia !== itemDesmembrado.sequencia &&
                    e.produtoCodigo === itemDesmembrado.produtoCodigo &&
                    e.produto?.id === itemDesmembrado.produto?.id &&
                    e.produto?.codigo === itemDesmembrado.produto?.codigo &&
                    e.produto?.descricao === itemDesmembrado.produto?.descricao
                ).length > 0;

              if (itemRepetido)
                listaItemDesmembradoRepetido += ` ${itemDesmembrado.sequencia},`;

              if (
                itemDesmembrado.codigoBarras &&
                !ValidarCodigoBarrasGtin(itemDesmembrado.codigoBarras)
              ) {
                listaMensagemErro.push(
                  `O Código de Barras do Item Desmembrado: ${itemDesmembrado.sequencia} é inválido!`
                );
              }

              if (!itemDesmembrado.unidadeMedida?.id) {
                listaMensagemErro.push(
                  `A Unidade de Medida do Item Desmembrado: ${itemDesmembrado.sequencia} é obrigatória!`
                );
              }

              if (!itemDesmembrado.produto?.descricao) {
                listaMensagemErro.push(
                  `O Produto do Item Desmembrado: ${itemDesmembrado.sequencia} é obrigatório!`
                );
              }

              if (itemDesmembrado.quantidadeEntradaEstoque === 0) {
                listaMensagemErro.push(
                  `A Quantidade Entrada/Estoque do Item Desmembrado: ${itemDesmembrado.sequencia} deve ser maior que 0!`
                );
              }

              if (itemDesmembrado.conversao <= 0) {
                listaMensagemErro.push(
                  `A Conversão do Item Desmembrado: ${itemDesmembrado.sequencia} deve ser maior que 0!`
                );
              }

              if (itemDesmembrado.percentual <= 0) {
                listaMensagemErro.push(
                  `O Percentual do Item Desmembrado: ${itemDesmembrado.sequencia} deve ser maior que 0!`
                );
              }
            }
          );

          if (!listaItemDesmembradoRepetido.IsNullOrEmpty()) {
            listaMensagemErro.push(
              `O Item: ${item.itemNf.sequencia}, possui os Itens Desmembrados: ${listaItemDesmembradoRepetido} repetido(s)!`
            );
          }
        }
      );

      if (listaMensagemAviso.length > 0) {
        TratarAvisos(listaMensagemAviso);
      }

      if (listaMensagemErro.length > 0) {
        TratarErros({ listaMensagem: listaMensagemErro });
        etapaValida = false;
      }

      const schema = Yup.object().shape({
        dataEntradaEstabelecimento: Yup.date()
          .nullable()
          .required('Data de Entrada no Estabelecimento é obrigatória!'),
        dataLancamento: Yup.date()
          .nullable()
          .required('Data de Lançamento é obrigatória!'),

        listaEtapa1: Yup.array().of(
          Yup.object().shape({
            listaItemDesmembrado: Yup.array().of(
              Yup.object().shape({
                codigoBarras: Yup.mixed()
                  .nullable()
                  .test({
                    message: 'O Código de Barras é inválido!',
                    test: (valor: any) => {
                      if (valor && !ValidarCodigoBarrasGtin(valor)) {
                        return false;
                      }
                      return true;
                    },
                  }),

                quantidadeEntradaEstoque: Yup.mixed()
                  .nullable()
                  .test({
                    message:
                      'A Quantidade Entrada/Estoque deve ser maior que 0!',
                    test: (valor) => {
                      if (valor === 0) {
                        return false;
                      }
                      return true;
                    },
                  }),

                conversao: Yup.mixed()
                  .nullable()
                  .test({
                    message: 'A Conversão deve ser maior que 0!',
                    test: (valor) => {
                      if (valor === 0) {
                        return false;
                      }
                      return true;
                    },
                  }),

                percentual: Yup.mixed()
                  .nullable()
                  .test({
                    message: 'A Percentual deve ser maior que 0!',
                    test: (valor) => {
                      if (valor === 0) {
                        return false;
                      }
                      return true;
                    },
                  }),
              })
            ),
          })
        ),
      });

      await schema.validate(data, { abortEarly: false });
      return etapaValida;
    } catch (error) {
      const errosValidacao = GetValidationErrors(error as any);
      formRef.current?.setErrors(errosValidacao);
      return false;
    }
  }, [formRef, handleObterDados]);

  const handleSetarListaItem = useCallback((listaValorItem: any[]): void => {
    setListaItem(listaValorItem);
  }, []);

  const handleObterListaItem =
    useCallback((): IImportacaoNfeTerceiroEtapa1ItemLista[] => {
      return listaItem;
    }, [listaItem]);

  const handleAbrirVinculoPedidoCompraItem = useCallback(
    (index: number, indexDesmembrado: number): void => {
      setAbrirVinculoPedidoCompra(true);

      setIndexItemSelecionado(index);
      setIndexItemDesmembradoSelecionado(indexDesmembrado);
    },
    []
  );

  const handleFecharVinculoPedidoCompraItem = useCallback((): void => {
    setAbrirVinculoPedidoCompra(false);
  }, []);

  const handleObterListaItensPedidosComprasVinculadas = useCallback(
    (listaValoresSelecionados: any[]) => {
      const listaPedidoCompraItem =
        formRef.current?.getFieldValue(
          `listaEtapa1[${indexItemSelecionado}].listaItemDesmembrado[${indexDesmembradoSelecionado}].listaPedidoCompraItem`
        ).listaValor ?? [];

      listaValoresSelecionados.forEach((item) => {
        item.quantidade = Number(item.quantidadeUtilizada);
        listaPedidoCompraItem.push(item);
      });

      formRef.current?.setFieldValue(
        `listaEtapa1[${indexItemSelecionado}].listaItemDesmembrado[${indexDesmembradoSelecionado}].listaPedidoCompraItem`,
        listaPedidoCompraItem
      );

      const listaPedidosComprasVinculados: any[] = [];

      listaValor.forEach((etapa1, indexEtapa1) => {
        etapa1.listaItemDesmembrado.forEach(
          (_: any, indexDesmembrado: number) => {
            const listaPedidoCompraItemEtapa1 = formRef.current?.getFieldValue(
              `listaEtapa1[${indexEtapa1}].listaItemDesmembrado[${indexDesmembrado}].listaPedidoCompraItem`
            ).listaValor;
            if (listaPedidoCompraItemEtapa1) {
              listaPedidoCompraItemEtapa1.forEach((pedidoCompraItem: any) => {
                listaPedidosComprasVinculados.push({
                  id: pedidoCompraItem.id,
                  quantidade: pedidoCompraItem.quantidadeUtilizada,
                });
              });
            }
          }
        );
      });

      setListaPedidosComprasVinculadosFront(listaPedidosComprasVinculados);
    },
    [formRef, indexDesmembradoSelecionado, indexItemSelecionado, listaValor]
  );

  useImperativeHandle(ref, () => {
    return {
      handleSetarListaItem,
      handleObterListaItem,
      handleValidar,
    };
  });

  // #region Atualizações

  const handleAtualizaListaItemDesmembrado = useCallback(
    async (quantidadeItensDesmembrar: number, index: number): Promise<void> => {
      setLoading(true);

      const data = (await handleObterDados()) as any;
      const itemValor = data.listaEtapa1[index];

      const itemValorSelecionado = itemValor.itemNf.entradaPeloProdutoTributado
        ? itemValor.itemNf.itemTributado
        : itemValor.itemNf.itemComercial;

      let percentual = 100 / quantidadeItensDesmembrar;

      if (itemValor.listaItemDesmembrado.length !== quantidadeItensDesmembrar) {
        if (quantidadeItensDesmembrar < itemValor.listaItemDesmembrado.length) {
          const quantidadeExcluir =
            itemValor.listaItemDesmembrado.length - quantidadeItensDesmembrar;

          let indexExcluir = itemValor.listaItemDesmembrado.length;
          for (let i = 0; i < quantidadeExcluir; i++) {
            indexExcluir -= 1;
            itemValor.listaItemDesmembrado.splice(indexExcluir, 1);
          }
        } else {
          const quantidadeInserir =
            quantidadeItensDesmembrar - itemValor.listaItemDesmembrado.length;

          let proximaSequencia = itemValor.listaItemDesmembrado.length;
          for (let i = 0; i < quantidadeInserir; i++) {
            proximaSequencia += 1;

            const itemDesmembrado: IImportacaoNfeTerceiroEtapa1ItemDesmembradoLista =
              {
                sequencia: `${itemValor.itemNf.sequencia}.${proximaSequencia}`,
                codigoBarras: itemValor.listaItemDesmembrado[0].codigoBarras,
                produtoCodigo: '',

                produto: itemValor.listaItemDesmembrado[0].produto,
                unidadeMedida: itemValor.listaItemDesmembrado[0].unidadeMedida,

                quantidadeEntradaEstoque:
                  itemValorSelecionado.quantidade *
                  itemValor.listaItemDesmembrado[0].conversao,
                conversao: itemValor.listaItemDesmembrado[0].conversao,
                percentual,
                valorUnitario:
                  itemValorSelecionado.valorUnitario *
                  (1 / itemValor.listaItemDesmembrado[0].conversao) *
                  (percentual / 100),
                valorTotal:
                  itemValorSelecionado.valorTotal * (percentual / 100),
              };

            itemValor.listaItemDesmembrado.push(itemDesmembrado);
          }
        }

        // #region Atualiza Itens Desmembrados

        refresh();
        await Sleep(50);

        const quantidadeEntradaEstoqueTotal =
          itemValor.listaItemDesmembrado.reduce(
            (acumulador: string, itemDesmembrado: any) => {
              acumulador += Number(itemDesmembrado.quantidadeEntradaEstoque);
              return acumulador;
            },
            0
          );

        for (let i = 0; i < itemValor.listaItemDesmembrado.length; i++) {
          const itemDesmembrado = itemValor.listaItemDesmembrado[i];

          const quantidadeEntradaEstoque =
            itemValorSelecionado.quantidade *
            itemValor.listaItemDesmembrado[0].conversao;
          percentual =
            (itemDesmembrado.quantidadeEntradaEstoque * 100) /
            quantidadeEntradaEstoqueTotal;
          const valorUnitario =
            itemValorSelecionado.valorUnitario *
            (1 / itemDesmembrado.conversao) *
            (percentual / 100);
          const ValorTotal =
            itemValorSelecionado.valorTotal * (percentual / 100);

          itemDesmembrado.quantidadeEntradaEstoque = quantidadeEntradaEstoque;
          itemDesmembrado.percentual = percentual;
          itemDesmembrado.valorUnitario = valorUnitario;
          itemDesmembrado.valorTotal = ValorTotal;

          formRef.current?.setFieldValue(
            `listaEtapa1[${index}].listaItemDesmembrado[${i}].sequencia`,
            itemDesmembrado.sequencia
          );
          formRef.current?.setFieldValue(
            `listaEtapa1[${index}].listaItemDesmembrado[${i}].codigoBarras`,
            itemDesmembrado.codigoBarras
          );
          formRef.current?.setFieldValue(
            `listaEtapa1[${index}].listaItemDesmembrado[${i}].produtoCodigo`,
            itemDesmembrado.produtoCodigo
          );
          formRef.current?.setFieldValorInicialSemExecutarEvento(
            `listaEtapa1[${index}].listaItemDesmembrado[${i}].produto`,
            itemDesmembrado.produto
          );
          formRef.current?.setFieldValorInicialSemExecutarEvento(
            `listaEtapa1[${index}].listaItemDesmembrado[${i}].unidadeMedida`,
            itemDesmembrado.unidadeMedida
          );
          formRef.current?.setFieldValue(
            `listaEtapa1[${index}].listaItemDesmembrado[${i}].quantidadeEntradaEstoque`,
            quantidadeEntradaEstoque
          );
          formRef.current?.setFieldValue(
            `listaEtapa1[${index}].listaItemDesmembrado[${i}].conversao`,
            itemDesmembrado.conversao
          );
          formRef.current?.setFieldValue(
            `listaEtapa1[${index}].listaItemDesmembrado[${i}].percentual`,
            percentual
          );
          formRef.current?.setFieldValue(
            `listaEtapa1[${index}].listaItemDesmembrado[${i}].valorUnitario`,
            valorUnitario
          );
          formRef.current?.setFieldValue(
            `listaEtapa1[${index}].listaItemDesmembrado[${i}].valorTotal`,
            ValorTotal
          );
        }

        // #endregion Atualiza Itens Desmembrados
      }

      setLoading(false);
      refresh();
    },
    [formRef, handleObterDados, refresh, setLoading]
  );

  const handleOnAtualizaPercentualListaItemDesmembrado = useCallback(
    async (index: number): Promise<void> => {
      const data = (await handleObterDados()) as any;
      const itemValor = data.listaEtapa1[index];

      const itemValorSelecionado = itemValor.itemNf.entradaPeloProdutoTributado
        ? itemValor.itemNf.itemTributado
        : itemValor.itemNf.itemComercial;

      // #region Atualiza Percentual Itens

      const quantidadeEntradaEstoqueTotal =
        itemValor.listaItemDesmembrado.reduce(
          (acumulador: string, itemDesmembrado: any) => {
            acumulador += itemDesmembrado.quantidadeEntradaEstoque;
            return acumulador;
          },
          0
        );

      for (let i = 0; i < itemValor.listaItemDesmembrado.length; i++) {
        const itemDesmembrado = itemValor.listaItemDesmembrado[i];

        let percentual = 0;
        if (quantidadeEntradaEstoqueTotal > 0) {
          percentual =
            (itemDesmembrado.quantidadeEntradaEstoque * 100) /
            quantidadeEntradaEstoqueTotal;
        }

        const valorUnitario =
          itemValorSelecionado.valorUnitario *
          (1 / itemDesmembrado.conversao) *
          (percentual / 100);
        const valorTotal = itemValorSelecionado.valorTotal * (percentual / 100);

        itemDesmembrado.percentual = percentual;
        itemDesmembrado.valorUnitario = valorUnitario;
        itemDesmembrado.valorTotal = valorTotal;

        formRef.current?.setFieldValue(
          `listaEtapa1[${index}].listaItemDesmembrado[${i}].percentual`,
          percentual
        );
        formRef.current?.setFieldValue(
          `listaEtapa1[${index}].listaItemDesmembrado[${i}].valorUnitario`,
          valorUnitario
        );
        formRef.current?.setFieldValue(
          `listaEtapa1[${index}].listaItemDesmembrado[${i}].valorTotal`,
          valorTotal
        );
      }

      // #endregion Atualiza Percentual Itens
    },
    [formRef, handleObterDados]
  );

  const handleOnAlterarUnidadeMedidaItemDesmembrado = useCallback(
    async (
      unidadeMedida: any,
      index: number,
      indexItemDesmembrado: number
    ): Promise<void> => {
      // #region Atualizar todos os sped do mesmo produto

      const { produto, produtoCodigo, codigoBarras, sequencia } =
        listaItem[index].listaItemDesmembrado[indexItemDesmembrado];

      if (!produto?.id) {
        for (let i = 0; i < listaItem.length; i++) {
          const itemLista = listaItem[i];

          for (let j = 0; j < itemLista.listaItemDesmembrado.length; j++) {
            const itemDesmembradoLista = itemLista.listaItemDesmembrado[j];

            if (
              itemDesmembradoLista.sequencia !== sequencia &&
              itemDesmembradoLista.produtoCodigo === produtoCodigo &&
              itemDesmembradoLista.produto?.id === produto?.id &&
              itemDesmembradoLista.produto?.codigo === produto?.codigo &&
              itemDesmembradoLista.produto?.descricao === produto?.descricao &&
              itemDesmembradoLista.codigoBarras === codigoBarras
            ) {
              formRef.current?.setFieldValorInicialSemExecutarEvento(
                `listaEtapa1[${i}].listaItemDesmembrado[${j}].unidadeMedida`,
                unidadeMedida
              );

              listaItem[i].listaItemDesmembrado[j].unidadeMedida =
                unidadeMedida;
            }
          }
        }
      }

      // #endregion Atualizar todos os sped do mesmo produto
    },
    [formRef, listaItem]
  );

  const handleOnAlterarQuantidadeEntradaEstoqueItemDesmembrado = useCallback(
    async (
      quantidadeEntradaEstoque: number,
      index: number,
      indexItemDesmembrado: number
    ): Promise<void> => {
      const data = (await handleObterDados()) as any;

      const itemValor = data.listaEtapa1[index];

      const itemValorSelecionado = itemValor.itemNf.entradaPeloProdutoTributado
        ? itemValor.itemNf.itemTributado
        : itemValor.itemNf.itemComercial;

      // #region Atualiza Conversao

      const conversao =
        quantidadeEntradaEstoque / Number(itemValorSelecionado.quantidade);

      listaItem[index].listaItemDesmembrado[indexItemDesmembrado].conversao =
        conversao;

      formRef.current?.setFieldValue(
        `listaEtapa1[${index}].listaItemDesmembrado[${indexItemDesmembrado}].conversao`,
        conversao
      );

      // #endregion Atualiza Conversao

      handleOnAtualizaPercentualListaItemDesmembrado(index);
    },
    [
      formRef,
      handleObterDados,
      handleOnAtualizaPercentualListaItemDesmembrado,
      listaItem,
    ]
  );

  const handleOnAlterarConversaoItemDesmembrado = useCallback(
    async (
      conversao: number,
      index: number,
      indexItemDesmembrado: number
    ): Promise<void> => {
      const data = (await handleObterDados()) as any;
      const itemValor = data.listaEtapa1[index];

      const itemValorSelecionado = itemValor.itemNf.entradaPeloProdutoTributado
        ? itemValor.itemNf.itemTributado
        : itemValor.itemNf.itemComercial;

      // #region Atualiza Conversao

      const quantidadeEntradaEstoque =
        conversao * Number(itemValorSelecionado.quantidade);

      listaItem[index].listaItemDesmembrado[
        indexItemDesmembrado
      ].quantidadeEntradaEstoque = quantidadeEntradaEstoque;

      formRef.current?.setFieldValue(
        `listaEtapa1[${index}].listaItemDesmembrado[${indexItemDesmembrado}].quantidadeEntradaEstoque`,
        quantidadeEntradaEstoque
      );

      // #endregion Atualiza Conversao

      handleOnAtualizaPercentualListaItemDesmembrado(index);
    },
    [
      formRef,
      handleObterDados,
      handleOnAtualizaPercentualListaItemDesmembrado,
      listaItem,
    ]
  );

  const handleOnAlterarPercentualItemDesmembrado = useCallback(
    async (
      percentual: number,
      index: number,
      indexItemDesmembrado: number
    ): Promise<void> => {
      const data = (await handleObterDados()) as any;
      const itemValor = data.listaEtapa1[index];

      const itemValorSelecionado = itemValor.itemNf.entradaPeloProdutoTributado
        ? itemValor.itemNf.itemTributado
        : itemValor.itemNf.itemComercial;

      // #region Atualiza Valores

      const valorUnitario =
        itemValorSelecionado.valorUnitario *
        (1 / itemValor.listaItemDesmembrado[0].conversao) *
        (percentual / 100);
      const valorTotal = itemValorSelecionado.valorTotal * (percentual / 100);

      listaItem[index].listaItemDesmembrado[
        indexItemDesmembrado
      ].valorUnitario = valorUnitario;
      listaItem[index].listaItemDesmembrado[indexItemDesmembrado].valorTotal =
        valorTotal;

      formRef.current?.setFieldValue(
        `listaEtapa1[${index}].listaItemDesmembrado[${indexItemDesmembrado}].valorUnitario`,
        valorUnitario
      );
      formRef.current?.setFieldValue(
        `listaEtapa1[${index}].listaItemDesmembrado[${indexItemDesmembrado}].valorTotal`,
        valorTotal
      );

      // #endregion Atualiza Valores
    },
    [formRef, handleObterDados, listaItem]
  );

  const handleOnAlterarCopiarCodigoDescricaoNovosProdutos = useCallback(
    async (copiarDescricao: boolean): Promise<void> => {
      const data = (await handleObterDados()) as any;
      const listaItemValor = data.listaEtapa1;

      for (let i = 0; i < listaItemValor.length; i++) {
        const itemValor = listaItemValor[i];

        for (let j = 0; j < itemValor.listaItemDesmembrado.length; j++) {
          const itemDesmembrado = itemValor.listaItemDesmembrado[j];

          let novaDescricao = itemDesmembrado.produto.descricao;
          if (copiarDescricao) {
            novaDescricao = `${itemValor.itemNf.itemComercial.produto.codigo} - ${itemDesmembrado.produto.descricao}`;
          } else if (
            String(itemDesmembrado.produto.descricao).startsWith(
              `${itemValor.itemNf.itemComercial.produto.codigo} - `
            )
          ) {
            novaDescricao = String(itemDesmembrado.produto.descricao).replace(
              `${itemValor.itemNf.itemComercial.produto.codigo} - `,
              ''
            );
          }

          const produtoAtualizado = {
            ...itemDesmembrado.produto,
            descricao: novaDescricao,
          };

          if (!itemDesmembrado.idProduto) {
            listaItem[i].listaItemDesmembrado[j].produto = produtoAtualizado;

            formRef.current?.setFieldValue(
              `listaEtapa1[${i}].listaItemDesmembrado[${j}].produto`,
              produtoAtualizado
            );
          }
        }
      }
    },
    [formRef, handleObterDados, listaItem]
  );

  const handleOnAlterarEntradaPeloProdutoTributado = useCallback(
    async (
      entradaPeloProdutoTributado: boolean,
      index: number
    ): Promise<void> => {
      const itemValor = listaItem[index];

      const itemValorSelecionado = entradaPeloProdutoTributado
        ? itemValor.itemNf.itemTributado
        : itemValor.itemNf.itemComercial;

      const copiarCodigoDescricaoNovosProdutos = formRef.current?.getFieldValue(
        'copiarCodigoDescricaoNovosProdutos'
      );

      for (let i = 0; i < itemValor.listaItemDesmembrado.length; i++) {
        const itemDesmembrado = itemValor.listaItemDesmembrado[i];

        const conversao =
          itemDesmembrado.quantidadeEntradaEstoque /
          Number(itemValorSelecionado?.quantidade);

        itemDesmembrado.conversao = conversao;

        formRef.current?.setFieldValue(
          `listaEtapa1[${index}].listaItemDesmembrado[${i}].conversao`,
          conversao
        );

        if (!itemDesmembrado.produto?.id) {
          const produto = {
            ...itemValorSelecionado?.produto,
          };

          if (!itemValorSelecionado?.produto.id) {
            produto.codigo = '';
          }

          if (copiarCodigoDescricaoNovosProdutos) {
            produto.descricao = `${itemValor.itemNf.itemComercial.produto.codigo} - ${produto.descricao}`;
          }

          if (itemValorSelecionado) {
            itemDesmembrado.codigoBarras = itemValorSelecionado.codigoBarras;
            itemDesmembrado.produto = produto as any;
            itemDesmembrado.unidadeMedida = itemValorSelecionado.unidadeMedida;

            formRef.current?.setFieldValue(
              `listaEtapa1[${index}].listaItemDesmembrado[${i}].codigoBarras`,
              itemValorSelecionado?.codigoBarras
            );
            formRef.current?.setFieldValue(
              `listaEtapa1[${index}].listaItemDesmembrado[${i}].produto`,
              produto
            );
            formRef.current?.setFieldValue(
              `listaEtapa1[${index}].listaItemDesmembrado[${i}].unidadeMedida`,
              itemValorSelecionado?.unidadeMedida
            );
          }
        }

        await handleOnAlterarConversaoItemDesmembrado(conversao, index, i);
      }
    },
    [formRef, handleOnAlterarConversaoItemDesmembrado, listaItem]
  );

  // #endregion Atualizações

  // #region Obter

  const handleObterPossuiProdutoCadastradoItemDesmembrado = useCallback(
    (index: number, indexItemDesmembrado: number): boolean => {
      const produto = formRef.current?.getFieldValue(
        `listaEtapa1[${index}].listaItemDesmembrado[${indexItemDesmembrado}].idProduto`
      );

      return produto;
    },
    [formRef]
  );

  const handleObterEntradaPeloProdutoTributado = useCallback(
    (index: number): boolean => {
      const entradaPeloProdutoTributado = formRef.current?.getFieldValue(
        `listaEtapa1[${index}].itemNf.entradaPeloProdutoTributado`
      );

      return entradaPeloProdutoTributado;
    },
    [formRef]
  );

  const handleObterPossuiItemTributado = useCallback(
    (index: number): boolean => {
      const { itemTributado } = listaItem[index].itemNf;

      return !!itemTributado?.produto;
    },
    [listaItem]
  );

  // #endregion Obter

  InputHidden({
    name: 'permiteAlterarPessoaCliente',
  });

  useEffect(() => {
    if (
      parametros.DataDeLancamento ===
      ImportacaoXmlTerceiroTipoDataEnum.dataAtual
    ) {
      formRef.current?.setFieldValue('dataEntradaEstabelecimento', new Date());
      formRef.current?.setFieldValue('dataLancamento', new Date());
    }
  });

  return (
    <div style={{ overflow: 'auto', height: 500 }}>
      <Divisor>
        <Row>
          <Col xl={2} lg={6} md={6} sm={12}>
            <InputDate
              name="dataEntradaEstabelecimento"
              label="Dt. Entrada Est."
            />
          </Col>
          <Col xl={4} lg={6} md={6} sm={12}>
            <InputDateTime name="dataLancamento" label="Data Lançamento" />
          </Col>
          <Col xl={4} lg={6} md={12} sm={12}>
            <InputLabel name="chaveAcesso" label="Chave de Acesso" />
          </Col>
          <Col xl={2} lg={6} md={12} sm={12}>
            <InputLabel
              name="valorTotalNota"
              label="Total da Nota"
              formatarValor={(valor) => {
                if (!valor) valor = 0;
                return Number(valor).FormatarParaPtBr();
              }}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={12} sm={12} className="div-checkbox">
            <CheckboxContainer>
              <InputCheckbox
                label="Copiar o código para a descrição em novos Produtos"
                name="copiarCodigoDescricaoNovosProdutos"
                onChange={(event) => {
                  handleOnAlterarCopiarCodigoDescricaoNovosProdutos(
                    event.currentTarget.checked
                  );
                }}
              />
            </CheckboxContainer>
          </Col>
          <Col lg={6} md={12} sm={12} className="div-checkbox">
            <CheckboxContainer>
              <InputCheckbox
                label="Pessoa é Cliente"
                name="pessoaCliente"
                disabled={
                  !formRef.current?.getFieldValue('permiteAlterarPessoaCliente')
                }
              />
            </CheckboxContainer>
          </Col>
        </Row>
      </Divisor>

      <Divisor>
        <Tabela style={{ overflow: 'auto' }}>
          <Table striped hover bordered variant="light">
            <tbody>
              {listaItem?.map((item, index) => (
                <>
                  <tr>
                    <td colSpan={4} style={{ padding: 0 }}>
                      <Divisor style={{ marginBottom: 0 }}>
                        <Row>
                          <Col
                            lg={1}
                            md={6}
                            sm={6}
                            style={{ fontWeight: 'normal' }}
                          >
                            <InputLabel
                              label="Seq."
                              name={`listaEtapa1[${index}].itemNf.sequencia`}
                            />
                          </Col>
                          <Col lg={3} md={6} sm={6}>
                            <InputLabel
                              label="Cód. (NF.)"
                              name={`listaEtapa1[${index}].itemNf.itemComercial.produto.codigo`}
                            />
                          </Col>
                          <Col lg={7} md={6} sm={12}>
                            <InputLabel
                              label="Descrição (NF.)"
                              name={`listaEtapa1[${index}].itemNf.itemComercial.produto.descricao`}
                            />
                          </Col>
                          <Col lg={1} md={6} sm={12}>
                            <InputLabel
                              label="CFOP"
                              name={`listaEtapa1[${index}].itemNf.cfop`}
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col lg={2} md={6} sm={12}>
                            <InputInteiro
                              label="Qtde. Desmembrar"
                              name={`listaEtapa1[${index}].itemNf.quantidadeItensDesmembrar`}
                              onBlur={async (event) => {
                                if (
                                  String(
                                    event.currentTarget.value
                                  ).ConverterParaNumber() === 0
                                ) {
                                  event.currentTarget.value = '1';
                                }

                                listaItem[
                                  index
                                ].itemNf.quantidadeItensDesmembrar = Number(
                                  event.currentTarget.value
                                );

                                await handleAtualizaListaItemDesmembrado(
                                  String(
                                    event.currentTarget.value
                                  ).ConverterParaNumber(),
                                  index
                                );
                              }}
                            />
                          </Col>

                          <div
                            style={{
                              display: !handleObterEntradaPeloProdutoTributado(
                                index
                              )
                                ? 'contents'
                                : 'none',
                            }}
                          >
                            <Col lg={2} md={6} sm={12}>
                              <InputLabel
                                label="Unid. (NF.)"
                                name={`listaEtapa1[${index}].itemNf.itemComercial.unidadeMedidaSigla`}
                              />
                            </Col>
                            <Col lg={2} md={6} sm={12}>
                              <InputLabel
                                label="Qtde. (NF.)"
                                name={`listaEtapa1[${index}].itemNf.itemComercial.quantidade`}
                                formatarValor={(valor) => {
                                  if (!valor) valor = 0;
                                  return Number(valor).FormatarParaPtBr({
                                    minimoCasasDecimais: 4,
                                    maximoCasasDecimais: 4,
                                  });
                                }}
                              />
                            </Col>
                            <Col lg={2} md={6} sm={12}>
                              <InputLabel
                                label="Valor Unitario. (NF.)"
                                name={`listaEtapa1[${index}].itemNf.itemComercial.valorUnitario`}
                                formatarValor={(valor) => {
                                  if (!valor) valor = 0;
                                  return Number(valor).FormatarParaPtBr({
                                    minimoCasasDecimais: 4,
                                    maximoCasasDecimais: 4,
                                  });
                                }}
                              />
                            </Col>
                            <Col lg={2} md={6} sm={12}>
                              <InputLabel
                                label="Valor Total. (NF.)"
                                name={`listaEtapa1[${index}].itemNf.itemComercial.valorTotal`}
                                formatarValor={(valor) => {
                                  if (!valor) valor = 0;
                                  return Number(valor).FormatarParaPtBr();
                                }}
                              />
                            </Col>
                          </div>

                          <div
                            style={{
                              display: handleObterEntradaPeloProdutoTributado(
                                index
                              )
                                ? 'contents'
                                : 'none',
                            }}
                          >
                            <Col lg={2} md={6} sm={12}>
                              <InputLabel
                                label="Unid. (NF.)"
                                name={`listaEtapa1[${index}].itemNf.itemTributado.unidadeMedidaSigla`}
                              />
                            </Col>
                            <Col lg={2} md={6} sm={12}>
                              <InputLabel
                                label="Qtde. (NF.)"
                                name={`listaEtapa1[${index}].itemNf.itemTributado.quantidade`}
                                formatarValor={(valor) => {
                                  if (!valor) valor = 0;
                                  return Number(valor).FormatarParaPtBr({
                                    minimoCasasDecimais: 4,
                                    maximoCasasDecimais: 4,
                                  });
                                }}
                              />
                            </Col>
                            <Col lg={2} md={6} sm={12}>
                              <InputLabel
                                label="Valor Unitario. (NF.)"
                                name={`listaEtapa1[${index}].itemNf.itemTributado.valorUnitario`}
                                formatarValor={(valor) => {
                                  if (!valor) valor = 0;
                                  return Number(valor).FormatarParaPtBr({
                                    minimoCasasDecimais: 4,
                                    maximoCasasDecimais: 4,
                                  });
                                }}
                              />
                            </Col>
                            <Col lg={2} md={6} sm={12}>
                              <InputLabel
                                label="Valor Total. (NF.)"
                                name={`listaEtapa1[${index}].itemNf.itemTributado.valorTotal`}
                                formatarValor={(valor) => {
                                  if (!valor) valor = 0;
                                  return Number(valor).FormatarParaPtBr();
                                }}
                              />
                            </Col>
                          </div>

                          <Col
                            lg={2}
                            md={6}
                            sm={12}
                            className="div-checkbox"
                            style={{
                              display: handleObterPossuiItemTributado(index)
                                ? 'flex'
                                : 'none',
                              alignItems: 'end',
                            }}
                          >
                            <CheckboxContainer>
                              <InputCheckbox
                                label="Entrada pelo Tributado"
                                name={`listaEtapa1[${index}].itemNf.entradaPeloProdutoTributado`}
                                onChange={(event) => {
                                  refresh();

                                  listaItem[
                                    index
                                  ].itemNf.entradaPeloProdutoTributado =
                                    event.currentTarget.checked;

                                  handleOnAlterarEntradaPeloProdutoTributado(
                                    event.currentTarget.checked,
                                    index
                                  );
                                }}
                              />
                            </CheckboxContainer>
                          </Col>
                        </Row>
                      </Divisor>
                    </td>
                  </tr>

                  {item.listaItemDesmembrado.map((_, indexItemDesmembrado) => (
                    <tr>
                      <td colSpan={4} style={{ padding: 0, paddingBottom: 0 }}>
                        <Divisor
                          style={{
                            borderRadius: 0,
                            marginBottom: 0,
                            backgroundColor:
                              indexItemDesmembrado % 2 === 0
                                ? '#fff'
                                : '#EEEEEE',
                          }}
                        >
                          <Row>
                            <Col
                              lg={1}
                              md={12}
                              sm={12}
                              style={{ fontWeight: 'normal' }}
                            >
                              <InputLabel
                                label="Seq."
                                name={`listaEtapa1[${index}].listaItemDesmembrado[${indexItemDesmembrado}].sequencia`}
                                labelStyle={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  color: 'darkBlue',
                                  fontWeight: 'bold',
                                }}
                                valorStyle={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  color: 'darkBlue',
                                  fontWeight: 'bold',
                                  fontSize: 22,
                                }}
                              />
                            </Col>
                            <Col lg={3} md={6} sm={6}>
                              <Input
                                label="Código de Barras"
                                name={`listaEtapa1[${index}].listaItemDesmembrado[${indexItemDesmembrado}].codigoBarras`}
                                placeholder="Código de Barras"
                                onChange={(event) => {
                                  listaItem[index].listaItemDesmembrado[
                                    indexItemDesmembrado
                                  ].codigoBarras = event.currentTarget.value;
                                }}
                                disabled={handleObterPossuiProdutoCadastradoItemDesmembrado(
                                  index,
                                  indexItemDesmembrado
                                )}
                              />
                            </Col>
                            <Col lg={3} md={6} sm={6}>
                              <Input
                                label="Código"
                                name={`listaEtapa1[${index}].listaItemDesmembrado[${indexItemDesmembrado}].produtoCodigo`}
                                placeholder="Código"
                                onChange={(event) => {
                                  listaItem[index].listaItemDesmembrado[
                                    indexItemDesmembrado
                                  ].produtoCodigo = event.currentTarget.value;
                                }}
                                disabled={handleObterPossuiProdutoCadastradoItemDesmembrado(
                                  index,
                                  indexItemDesmembrado
                                )}
                              />
                            </Col>
                            <Col lg={5} md={12} sm={12}>
                              <InputAutoCompleteProduto
                                label="Produto"
                                name={`listaEtapa1[${index}].listaItemDesmembrado[${indexItemDesmembrado}].idProduto`}
                                nomeObjeto={`listaEtapa1[${index}].listaItemDesmembrado[${indexItemDesmembrado}].produto`}
                                placeholder="Produto"
                                onChangeItemAtual={(event) => {
                                  if (event.itemAtual) {
                                    listaItem[index].listaItemDesmembrado[
                                      indexItemDesmembrado
                                    ].produto = event.itemAtual;

                                    listaItem[index].listaItemDesmembrado[
                                      indexItemDesmembrado
                                    ].codigoBarras = event.itemAtual
                                      ?.produtoEngenharia.codigoBarras
                                      ? event.itemAtual?.produtoEngenharia
                                          .codigoBarras
                                      : '';

                                    listaItem[index].listaItemDesmembrado[
                                      indexItemDesmembrado
                                    ].produtoCodigo = event.itemAtual?.codigo;
                                  } else {
                                    listaItem[index].listaItemDesmembrado[
                                      indexItemDesmembrado
                                    ].produto = undefined;

                                    listaItem[index].listaItemDesmembrado[
                                      indexItemDesmembrado
                                    ].codigoBarras = '';

                                    listaItem[index].listaItemDesmembrado[
                                      indexItemDesmembrado
                                    ].produtoCodigo = '';
                                  }

                                  formRef.current?.setFieldValue(
                                    `listaEtapa1[${index}].listaItemDesmembrado[${indexItemDesmembrado}].codigoBarras`,
                                    event.itemAtual?.produtoEngenharia
                                      .codigoBarras
                                  );
                                  formRef.current?.setFieldValue(
                                    `listaEtapa1[${index}].listaItemDesmembrado[${indexItemDesmembrado}].produtoCodigo`,
                                    event.itemAtual?.codigo
                                  );
                                  if (
                                    event.itemAtual?.produtoEstoque
                                      .unidadeMedida.id
                                  ) {
                                    formRef.current?.setFieldValue(
                                      `listaEtapa1[${index}].listaItemDesmembrado[${indexItemDesmembrado}].unidadeMedida`,
                                      event.itemAtual?.produtoEstoque
                                        .unidadeMedida
                                    );

                                    listaItem[index].listaItemDesmembrado[
                                      indexItemDesmembrado
                                    ].unidadeMedida =
                                      event.itemAtual?.produtoEstoque.unidadeMedida;
                                  }

                                  refresh();
                                }}
                                registroSeraCadastradoAutomaticamente
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col lg={3} md={6} sm={12}>
                              <InputAutoCompleteUnidadeMedida
                                label="Unidade Medida (Produto)"
                                name={`listaEtapa1[${index}].listaItemDesmembrado[${indexItemDesmembrado}].idUnidadeMedida`}
                                nomeObjeto={`listaEtapa1[${index}].listaItemDesmembrado[${indexItemDesmembrado}].unidadeMedida`}
                                placeholder="Unidade Medida (Produto)"
                                onChangeItemAtual={(event) => {
                                  listaItem[index].listaItemDesmembrado[
                                    indexItemDesmembrado
                                  ].unidadeMedida =
                                    event.itemAtual ?? undefined;

                                  handleOnAlterarUnidadeMedidaItemDesmembrado(
                                    event.itemAtual,
                                    index,
                                    indexItemDesmembrado
                                  );
                                }}
                                disabled={handleObterPossuiProdutoCadastradoItemDesmembrado(
                                  index,
                                  indexItemDesmembrado
                                )}
                              />
                            </Col>
                            <Col lg={3} md={6} sm={12}>
                              <InputDecimal
                                label="Qtde. Entrada/Estoque"
                                name={`listaEtapa1[${index}].listaItemDesmembrado[${indexItemDesmembrado}].quantidadeEntradaEstoque`}
                                casasDecimais={4}
                                onBlur={(event) => {
                                  const quantidadeEntradaEstoque = event
                                    .currentTarget.value
                                    ? String(
                                        event.currentTarget.value
                                      ).ConverterParaNumber()
                                    : 0;

                                  listaItem[index].listaItemDesmembrado[
                                    indexItemDesmembrado
                                  ].quantidadeEntradaEstoque =
                                    quantidadeEntradaEstoque;

                                  handleOnAlterarQuantidadeEntradaEstoqueItemDesmembrado(
                                    quantidadeEntradaEstoque,
                                    index,
                                    indexItemDesmembrado
                                  );

                                  formRef.current?.setFieldValue(
                                    `listaEtapa1[${index}].listaItemDesmembrado[${indexItemDesmembrado}].quantidadeEntradaEstoque`,
                                    quantidadeEntradaEstoque
                                  );
                                }}
                              />
                            </Col>
                            <Col lg={3} md={6} sm={12}>
                              <InputDecimal
                                label="Conv. (NF. Entrada x Estoque)"
                                name={`listaEtapa1[${index}].listaItemDesmembrado[${indexItemDesmembrado}].conversao`}
                                casasDecimais={10}
                                onBlur={(event) => {
                                  const conversao = event.currentTarget.value
                                    ? String(
                                        event.currentTarget.value
                                      ).ConverterParaNumber()
                                    : 0;

                                  listaItem[index].listaItemDesmembrado[
                                    indexItemDesmembrado
                                  ].conversao = conversao;

                                  handleOnAlterarConversaoItemDesmembrado(
                                    conversao,
                                    index,
                                    indexItemDesmembrado
                                  );

                                  formRef.current?.setFieldValue(
                                    `listaEtapa1[${index}].listaItemDesmembrado[${indexItemDesmembrado}].conversao`,
                                    conversao
                                  );
                                }}
                              />
                            </Col>
                            <Col lg={3} md={6} sm={12}>
                              <InputDecimal
                                label="Perc. (Val. do Item)"
                                name={`listaEtapa1[${index}].listaItemDesmembrado[${indexItemDesmembrado}].percentual`}
                                casasDecimais={10}
                                onBlur={(event) => {
                                  const percentual = event.currentTarget.value
                                    ? String(
                                        event.currentTarget.value
                                      ).ConverterParaNumber()
                                    : 0;
                                  if (percentual > 100) {
                                    event.currentTarget.value = '100';
                                  }

                                  listaItem[index].listaItemDesmembrado[
                                    indexItemDesmembrado
                                  ].percentual = percentual;

                                  handleOnAlterarPercentualItemDesmembrado(
                                    percentual,
                                    index,
                                    indexItemDesmembrado
                                  );
                                }}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col
                              lg={9}
                              md={6}
                              sm={12}
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <InputLabel
                                label="Val. Unitário"
                                name={`listaEtapa1[${index}].listaItemDesmembrado[${indexItemDesmembrado}].valorUnitario`}
                                formatarValor={(valor) => {
                                  if (!valor) valor = 0;
                                  return Number(valor).FormatarParaPtBr({
                                    minimoCasasDecimais: 4,
                                    maximoCasasDecimais: 4,
                                  });
                                }}
                                valorStyle={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}
                              />
                            </Col>
                            <Col
                              lg={3}
                              md={6}
                              sm={12}
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <InputLabel
                                label="Val. Total"
                                name={`listaEtapa1[${index}].listaItemDesmembrado[${indexItemDesmembrado}].valorTotal`}
                                formatarValor={(valor) => {
                                  if (!valor) valor = 0;
                                  return Number(valor).FormatarParaPtBr();
                                }}
                                valorStyle={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                }}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col
                              xl={3}
                              lg={6}
                              sm={6}
                              xs={12}
                              className="bottom"
                            >
                              <BtnPadraoButton
                                type="button"
                                className="btn-padrao btn-verde-claro btn-adicionar"
                                onClick={() =>
                                  handleAbrirVinculoPedidoCompraItem(
                                    index,
                                    indexItemDesmembrado
                                  )
                                }
                              >
                                <IoMdAdd />
                                <span style={{ marginLeft: 10 }}>
                                  Vinc. Pedido Compra
                                </span>
                              </BtnPadraoButton>
                            </Col>
                          </Row>

                          <Row>
                            <Col>
                              <InputAutoCompleteTagPedidoCompraItem
                                name={`listaEtapa1[${index}].listaItemDesmembrado[${indexItemDesmembrado}].listaPedidoCompraItem`}
                                label="Pedidos Compras Vinculados"
                                listaPedidosComprasVinculadosFront={
                                  listaPedidosComprasVinculadosFront
                                }
                                onChangeListaItemAtual={(ev) => {
                                  listaItem[index].listaItemDesmembrado[
                                    indexItemDesmembrado
                                  ].listaPedidoCompraItem =
                                    ev.listaItemAtual ?? undefined;
                                }}
                                disabled
                              />
                            </Col>
                          </Row>
                        </Divisor>
                      </td>
                    </tr>
                  ))}

                  <tr>
                    <td
                      colSpan={4}
                      style={{
                        padding: 0,
                        paddingBottom: 20,
                        backgroundColor: '#3397C4',
                      }}
                    />
                  </tr>
                </>
              ))}
            </tbody>
          </Table>
        </Tabela>
      </Divisor>

      {abrirVinculoPedidoCompra && (
        <VinculoPedidoCompraHook>
          <VinculoPedidoCompraLista
            titulo="Vínculo de Pedidos Compra"
            onConcluirVinculoPedido={
              handleObterListaItensPedidosComprasVinculadas
            }
            listaEtapa1={formRef.current?.getFieldValue('listaEtapa1')}
            formRefPai={formRef}
            onFecharFormModal={handleFecharVinculoPedidoCompraItem}
            loading={loading}
            setLoading={setLoading}
          />
        </VinculoPedidoCompraHook>
      )}
    </div>
  );
};

export default forwardRef(ImportacaoNfeTerceiroEtapa1);
